<div class="box-wrapper" xmlns="http://www.w3.org/1999/html" xmlns="http://www.w3.org/1999/html">

  <div class="header">
    <div class="box-switcher">
      <span>EN</span>
      <div
        class="language-switcher"
        [class.selected-en]="currentLanguage !== 'fr-CA'"
        [class.selected-fr]="currentLanguage === 'fr-CA'"
      >
        <div class="slider"></div>
        <button
          [class.en]="currentLanguage !== 'fr-CA'"
          (click)="changeLanguage(currentLanguage === 'fr-CA' ? 'en-CA' : 'fr-CA')"
        ></button>
        <button
          [class.fr]="currentLanguage === 'fr-CA'"
          (click)="changeLanguage(currentLanguage === 'fr-CA' ? 'en-CA' : 'fr-CA')"
        ></button>
      </div>
      <span>FR</span>
    </div>

    <div class="title">{{ 'serviceCenter.title' | translate }}</div>

    <div class="header__item">
      <div class="logos">
        <img class="logo" src="/themes/boston-pizza/assets/images/logo_service_centre.svg" alt="">
      </div>

      <div class="table-name">
        <span>{{ 'serviceCenter.table' | translate }} {{ tableName }}</span>
      </div>

      <button
        *ngIf="withCallWaiter && withCallWaiterFeatures"
        class="call-waiter-btn"
        [disabled]="callWaiterToPay || callWaiterToRepeat"
        (click)="handlerCallWaiter($event)"
        [class.active]="callWaiter"
      >
        <div class="bell">
          <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M29.3333 14.5094C28.5973 14.5094 28 13.9121 28 13.1761C28 9.43609 26.544 5.92142 23.9 3.27609C23.3787 2.75476 23.3787 1.91209 23.9 1.39076C24.4213 0.869423 25.264 0.869423 25.7853 1.39076C28.9333 4.53876 30.6667 8.72409 30.6667 13.1761C30.6667 13.9121 30.0693 14.5094 29.3333 14.5094Z"
              fill="white"
            />
            <path
              d="M2.66639 14.5092C1.93039 14.5092 1.33306 13.9118 1.33306 13.1758C1.33306 8.72384 3.06639 4.53851 6.21439 1.39051C6.73573 0.869178 7.57839 0.869178 8.09973 1.39051C8.62106 1.91184 8.62106 2.75451 8.09973 3.27584C5.45573 5.91984 3.99973 9.43584 3.99973 13.1758C3.99973 13.9118 3.40239 14.5092 2.66639 14.5092Z"
              fill="white"
            />
            <path
              d="M28.5056 22.5507C26.4896 20.8467 25.3336 18.356 25.3336 15.7173V12C25.3336 7.308 21.8483 3.424 17.3336 2.77333V1.33333C17.3336 0.596 16.7363 0 16.0003 0C15.2643 0 14.6669 0.596 14.6669 1.33333V2.77333C10.1509 3.424 6.66694 7.308 6.66694 12V15.7173C6.66694 18.356 5.51094 20.8467 3.48294 22.5613C2.96427 23.0053 2.66694 23.6507 2.66694 24.3333C2.66694 25.62 3.71361 26.6667 5.00027 26.6667H27.0003C28.2869 26.6667 29.3336 25.62 29.3336 24.3333C29.3336 23.6507 29.0363 23.0053 28.5056 22.5507Z"
              fill="white"
            />
            <path
              d="M16.0006 32C18.4153 32 20.4353 30.2787 20.8993 28H11.102C11.566 30.2787 13.586 32 16.0006 32Z"
              fill="white"
            />
          </svg>
        </div>

        <div class="call-waiter-btn-content" [class.active]="!callWaiter">
          <span>{{ 'serviceCenter.callWaiter' | translate }}</span>
        </div>

        <div class="call-waiter-btn-content small" [class.active]="callWaiter">
          <span>{{ 'serviceCenter.callWaiterCancel' | translate }}</span>
        </div>
      </button>
    </div>
  </div>

  <app-bp-sc-sections class="box-sections"></app-bp-sc-sections>

  <div class="box-content">
    <div *ngIf="wifiName" class="box-content__wrapper">
      <div class="icon">
        <img src="/themes/boston-pizza/assets/images/wifi.svg" alt=""/>
      </div>
      <div class="info">
        <div class="network">
          <span class="network__title">{{ 'serviceCenter.wifi.name' | translate }}: </span>
          <span class="name">{{ wifiName }}</span>
        </div>
        <div *ngIf="wifiPassword" class="network">
          <span class="network__title">{{ 'serviceCenter.wifi.pass' | translate }}: </span>
          <span class="password">{{ wifiPassword }}</span>
        </div>
      </div>
    </div>
    <div class="box-content__wrapper">
      <div class="icon">
        <img src="/themes/boston-pizza/assets/images/charging.svg" alt=""/>
      </div>
      <div class="info">
        <div class="network">
          <span class="network__title">{{ 'serviceCenter.chargeDevice.title' | translate }}</span>
          <span class="name" [innerHTML]="'serviceCenter.chargeDevice.wired' | translate"></span>
        </div>
      </div>
    </div>
  </div>
</div>
