import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';
import { AuthPageComponent, MainPageComponent } from './pages';
import { MenuTabComponent } from './pages/main-page/menu-tab/menu-tab.component';
import { HomeTabComponent } from './pages/main-page/home-tab/home-tab.component';
import { InvoiceTabComponent } from './pages/main-page/invoice-tab/invoice-tab.component';
import { ServiceCentreComponent } from './pages/main-page/service-centre/service-centre.component';
import { SpecialOfferComponent } from './pages/main-page/special-offer/special-offer.component';
import { PlayTabComponent } from '../themes/jack-astors/pages/main-page/play-tab/play-tab.component';
import { WatchTabComponent } from '../themes/jack-astors/pages/main-page/watch-tab/watch-tab.component';
import { MoreTabComponent } from '../themes/jack-astors/pages/main-page/more-tab/more-tab.component';
import { GetRewardedTabComponent } from '../themes/jack-astors/pages/main-page/get-rewarded-tab/get-rewarded-tab.component';
import { CharityTabComponent } from '../themes/the-keg/pages/main-page/charity-tab/charity-tab.component';
import { StoreTabComponent } from '../themes/the-keg/pages/main-page/store-tab/store-tab.component';
import { AppTabComponent } from '../themes/original-joe/pages/main-page/app-tab/app-tab.component';
import { GiftTabComponent } from '../themes/original-joe/pages/main-page/gift-tab/gift-tab.component';
import { CbhMoreTabComponent } from '../themes/canadian-brewhouse/pages/main-page/cbh-more/cbh-more-tab.component';
import { CbhStoreTabComponent } from '../themes/canadian-brewhouse/pages/main-page/cbh-store-tab/cbh-store-tab.component';
import { CbhRewardsTabComponent } from '../themes/canadian-brewhouse/pages/main-page/cbh-rewards-tab/cbh-rewards-tab.component';
import { NewsPageComponent } from '../themes/boston-pizza/pages/main-page/news-page/news-page.component';
import { SpecialTabComponent } from './pages/main-page/special-tab/special.component';
import { NewMenuTabComponent } from '../themes/kelseys/pages/main-page/new-menu-tab/new-menu-tab.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: '/main/home'
  },
  {
    path: 'auth',
    component: AuthPageComponent
  },
  {
    path: 'main',
    component: MainPageComponent,
    children: [
      {
        path: 'menu',
        component: MenuTabComponent,
        data: { animation: 'MenuTab' }
      },
      {
        path: 'home',
        component: HomeTabComponent,
        data: { animation: 'HomeTab' },
        children: [
          {
            path: 'service',
            component: ServiceCentreComponent,
            data: { animation: 'ServiceCentre'},
          },
          {
            path: 'special-offer',
            component: SpecialOfferComponent,
            data: { animation: 'SpecialOffer' }
          },
          {
            path: 'news',
            component: NewsPageComponent,
            data: { animation: 'ServiceCentre' }
          },
        ]
      },
      {
        path: 'invoice',
        component: InvoiceTabComponent,
        data: { animation: 'InvoiceTab' }
      },
      {
        path: 'jack-astors/play',
        component: PlayTabComponent,
        data: { animation: 'PlayTab' }
      },
      {
        path: 'jack-astors/watch',
        component: WatchTabComponent,
        data: { animation: 'WatchTab' }
      },
      {
        path: 'jack-astors/more',
        component: MoreTabComponent,
        data: { animation: 'MoreTab' }
      },
      {
        path: 'jack-astors/rewarded',
        component: GetRewardedTabComponent,
        data: { animation: 'GetRewardedTab' }
      },
      {
        path: 'the-keg/menu',
        component: MenuTabComponent,
        data: { animation: 'PlayTab' }
      },
      {
        path: 'the-keg/invoice',
        component: InvoiceTabComponent,
        data: { animation: 'GetRewardedTab' }
      },
      {
        path: 'the-keg/charity',
        component: CharityTabComponent,
        data: { animation: 'WatchTab' }
      },
      {
        path: 'the-keg/store',
        component: StoreTabComponent,
        data: { animation: 'MoreTab' }
      },
      {
        path: 'original-joe/menu',
        component: MenuTabComponent,
        data: { animation: 'PlayTab' }
      },
      {
        path: 'original-joe/invoice',
        component: InvoiceTabComponent,
        data: { animation: 'GetRewardedTab' }
      },
      {
        path: 'original-joe/app',
        component: AppTabComponent,
        data: { animation: 'WatchTab' }
      },
      {
        path: 'original-joe/gift',
        component: GiftTabComponent,
        data: { animation: 'MoreTab' }
      },
      {
        path: 'kelseys/menu',
        component: NewMenuTabComponent,
        data: { animation: 'PlayTab' }
      },
      {
        path: 'kelseys/special',
        component: SpecialTabComponent,
        data: { animation: 'MoreTab' }
      },
      {
        path: 'canadian-brewhouse/menu',
        component: MenuTabComponent,
        data: { animation: 'PlayTab' }
      },
      {
        path: 'canadian-brewhouse/more',
        component: CbhMoreTabComponent,
        data: { animation: 'GetRewardedTab' }
      },
      {
        path: 'canadian-brewhouse/store',
        component: CbhStoreTabComponent,
        data: { animation: 'WatchTab' }
      },
      {
        path: 'canadian-brewhouse/rewarded',
        component: CbhRewardsTabComponent,
        data: { animation: 'MoreTab' }
      },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    preloadingStrategy: PreloadAllModules,
    relativeLinkResolution: 'legacy',
    onSameUrlNavigation: 'reload'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
