import { environment } from '../environments/environment';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { ServiceWorkerModule } from '@angular/service-worker';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { PinchZoomModule } from 'ngx-pinch-zoom';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SafeResourceUrlPipe } from './pipes/SafeResourceUrlPipe';
import { AppDatabaseModule } from './app-database.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { RestaurantMenuImageComponent } from './components/restaurant-menu-image/restaurant-menu-image.component';
import { AuthPageComponent, MainPageComponent } from './pages';
import { MenuTabComponent } from './pages/main-page/menu-tab/menu-tab.component';
import { HomeTabComponent } from './pages/main-page/home-tab/home-tab.component';
import { InvoiceTabComponent } from './pages/main-page/invoice-tab/invoice-tab.component';
import { TabBarComponent } from './components/tab-bar/tab-bar.component';
import { HappyHoursComponent } from './components/happy-hours/happy-hours.component';
import { ServiceCentreComponent } from './pages/main-page/service-centre/service-centre.component';
import { SpecialOfferComponent } from './pages/main-page/special-offer/special-offer.component';
import { ConfettiComponent } from './components/confetti/confetti.component';
import { LoadingComponent } from './components/loading/loading.component';
import { BackgroundPlayerComponent } from './components/background-player/background-player.component';
import { LottieModule } from 'ngx-lottie';
import player, { LottiePlayer } from 'lottie-web';
import { MoreTabComponent } from '../themes/jack-astors/pages/main-page/more-tab/more-tab.component';
import { PlayTabComponent } from '../themes/jack-astors/pages/main-page/play-tab/play-tab.component';
import { WatchTabComponent } from '../themes/jack-astors/pages/main-page/watch-tab/watch-tab.component';
import { GetRewardedTabComponent } from '../themes/jack-astors/pages/main-page/get-rewarded-tab/get-rewarded-tab.component';
import { ChalkboardsComponent } from '../themes/jack-astors/components/chalkboards/chalkboards.component';
import { JaksVideoComponent } from '../themes/jack-astors/components/jaks-video/jaks-video.component';
import { JaksSportsComponent } from '../themes/jack-astors/components/jaks-sports/jaks-sports.component';
import { QrViewComponent } from './components/qr-view/qr-view.component';
import { CharityTabComponent } from '../themes/the-keg/pages/main-page/charity-tab/charity-tab.component';
import * as Sentry from '@sentry/angular';
import { StoreTabComponent } from '../themes/the-keg/pages/main-page/store-tab/store-tab.component';
import { AppTabComponent } from '../themes/original-joe/pages/main-page/app-tab/app-tab.component';
import { GiftTabComponent } from '../themes/original-joe/pages/main-page/gift-tab/gift-tab.component';
import { ObserveElementDirective } from './directives/observe-element.directive';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { KelseysSectionsGridComponent } from '../themes/kelseys/components/kelseys-sections-grid/kelseys-sections-grid.component';
import { KelseysMoreSectionComponent } from '../themes/kelseys/components/kelseys-more-section/kelseys-more-section.component';
import { CbhMoreTabComponent } from '../themes/canadian-brewhouse/pages/main-page/cbh-more/cbh-more-tab.component';
import { CbhStoreTabComponent } from '../themes/canadian-brewhouse/pages/main-page/cbh-store-tab/cbh-store-tab.component';
import { CbhRewardsTabComponent } from '../themes/canadian-brewhouse/pages/main-page/cbh-rewards-tab/cbh-rewards-tab.component';
import { BpScSectionsComponent } from '../themes/boston-pizza/components/bp-sc-sections/bp-sc-sections.component';
import { BpMoreComponent } from '../themes/boston-pizza/components/bp-sc-sections/bp-more/bp-more.component';
import { BpRewardsComponent } from '../themes/boston-pizza/components/bp-sc-sections/bp-rewards/bp-rewards.component';
import { NewsPageComponent } from '../themes/boston-pizza/pages/main-page/news-page/news-page.component';
import { SpecialTabComponent } from './pages/main-page/special-tab/special.component';
import { NewMenuTabComponent } from '../themes/kelseys/pages/main-page/new-menu-tab/new-menu-tab.component';
import { MenuCategoryComponent } from '../themes/kelseys/components/menu-category/menu-category.component';
import { ProductPreviewComponent } from '../themes/kelseys/components/product/product-preview/product-preview.component';




export function playerFactory(): LottiePlayer {
  return player;
}

@NgModule({
  declarations: [
    ObserveElementDirective,
    AppComponent,
    RestaurantMenuImageComponent,
    TabBarComponent,
    HappyHoursComponent,
    MainPageComponent,
    MenuTabComponent,
    HomeTabComponent,
    InvoiceTabComponent,
    SpecialTabComponent,
    AuthPageComponent,
    ConfettiComponent,
    LoadingComponent,
    ServiceCentreComponent,
    SpecialOfferComponent,
    BackgroundPlayerComponent,
    SafeResourceUrlPipe,
    PlayTabComponent,
    WatchTabComponent,
    MoreTabComponent,
    GetRewardedTabComponent,
    QrViewComponent,
    ChalkboardsComponent,
    JaksVideoComponent,
    JaksSportsComponent,
    CharityTabComponent,
    StoreTabComponent,
    AppTabComponent,
    GiftTabComponent,
    KelseysSectionsGridComponent,
    KelseysMoreSectionComponent,
    CbhMoreTabComponent,
    CbhStoreTabComponent,
    CbhRewardsTabComponent,
    BpScSectionsComponent,
    BpMoreComponent,
    BpRewardsComponent,
    NewsPageComponent,
    NewMenuTabComponent,
    MenuCategoryComponent,
    ProductPreviewComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppDatabaseModule,
    AppRoutingModule,
    HttpClientModule,
    PinchZoomModule,
    LottieModule.forRoot({ player: playerFactory }),
    ServiceWorkerModule.register('service-worker.js', {
      enabled: environment.serviceWorker,
      registrationStrategy: 'registerImmediately'
    }),
    TranslateModule.forRoot({
      defaultLanguage: 'en-CA',
      useDefaultLang: true,
      isolate: true,
      loader: {
        provide: TranslateLoader,
        useFactory: (http: HttpClient) => new TranslateHttpLoader(http, '/assets/locale/', '.json'),
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent]
})

export class AppModule {}
