<app-loading *ngIf="isLoading; else content"></app-loading>

<ng-template #content>
    <div class="categories">
      <ng-container *ngIf="isHHNav">
        <button class="category happy-hours"
                [class.active]=isActiveCategory(menu.name)
                *ngFor="let menu of happyHoursMenus"
                (click)="setActiveTab('tabHappyHours', menu.name); setHappyHoursMenusCurrent(menu)"
        >
          <img class="icon happy-hours" src="/themes/boston-pizza/assets/images/appy_hour_item.png" alt="">
        </button>
      </ng-container>
      <button class="category"
              [class.active]=isActiveCategory(menu.name)
              *ngFor="let menu of mainMenus"
              (click)="setActiveTab('tabMain', menu.name); setMainMenusCurrent(menu);"
      >
        <img *ngIf="!menu.iconLocal" class="icon" src="/themes/boston-pizza/assets/images/menu.svg" alt="">
        <img *ngIf="menu.iconLocal" class="icon" [src]="menu.iconLocal" alt="">
        <span class="name" [class.active]=isActiveCategory(menu.name)>
          {{ menu.name }}
        </span>
      </button>
      <ng-container *ngIf="!isHHNav">
        <button class="category happy-hours"
                [class.active]=isActiveCategory(menu.name)
                *ngFor="let menu of happyHoursMenus"
                (click)="setActiveTab('tabHappyHours', menu.name); setHappyHoursMenusCurrent(menu)"
        >
          <img class="icon happy-hours" src="/themes/boston-pizza/assets/images/appy_hour_item.png" alt="">
        </button>
      </ng-container>
    </div>

  <div class="empty" *ngIf="happyHoursMenus.length === 0 && mainMenus.length === 0 else menus">
    <img src="/themes/boston-pizza/assets/images/menu.svg" alt="" />
    <div>{{ 'menu.empty' | translate }}</div>
  </div>

  <ng-template #menus>
    <div class="menu" *ngIf="tabs.activeId == 'tabMain'">
      <pinch-zoom *ngFor="let url of mainMenusCurrent ? mainMenusCurrent.pagesLocal : mainMenus[0].pagesLocal">
        <img [src]="url" loading="lazy" alt="">
      </pinch-zoom>
    </div>

    <div class="menu" *ngIf="tabs.activeId == 'tabHappyHours'">
      <pinch-zoom *ngFor="let url of happyHoursMenusCurrent ? happyHoursMenusCurrent.pagesLocal : happyHoursMenus[0].pagesLocal">
        <img [src]="url" loading="lazy" alt="">
      </pinch-zoom>
    </div>
  </ng-template>
</ng-template>

