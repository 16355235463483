<div class="sections">
  <div class="section rewards" (click)="setState('rewards')">
    <img src="/themes/boston-pizza/assets/images/rewards.png" alt="rewards"/>
    <span>{{ 'serviceCenter.sections.rewards.rewards' | translate }}</span>
  </div>
  <div class="section promo" (click)="setState('promo')">
    <img src="/themes/boston-pizza/assets/images/promos_offers.png" alt="rewards"/>
    <span>{{ 'serviceCenter.sections.promo.promo' | translate }}</span>
  </div>
  <div class="section gift" (click)="setState('gift')">
    <img src="/themes/boston-pizza/assets/images/gift_cards.png" alt="rewards"/>
    <span>{{ 'serviceCenter.sections.gift.gift' | translate }}</span>
  </div>
  <div class="section more" (click)="setState('more')">
    <img src="/themes/boston-pizza/assets/images/more.png" alt="rewards"/>
    <span>{{ 'serviceCenter.sections.more.more' | translate }}</span>
  </div>
</div>

<app-bp-more
  *ngIf="state === 'more'"
  @InOutAnimation
  (backToSections)="setState('main')"
>
</app-bp-more>

<app-bp-rewards
  *ngIf="state === 'rewards'"
  @InOutAnimation
  (click)="setState('main')"
></app-bp-rewards>

<app-qr-view
  *ngIf="state === 'gift'"
  @InOutAnimation
  (click)="setState('main')"
></app-qr-view>

<app-special
  *ngIf="state === 'promo'"
  @InOutAnimation
  (backToSections)="setState('main')"
></app-special>
