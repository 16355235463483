<div class="title">
  <h1>{{ 'play.title' | translate }}</h1>
</div>

<div class="game__list">
  <div class="game__list__card" (click)="showQRCode('Trivia', '/themes/jack-astors/assets/images/qr/trivia.svg')">
    <img src="/themes/jack-astors/assets/images/game/trivia.jpg" alt="Trivia">
    <div class="description">
      <h3>Trivia</h3>
      <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</span>
    </div>
  </div>

  <div class="game__list__card" (click)="showQRCode('Axe Master', '/themes/jack-astors/assets/images/qr/axe.svg')">
    <img src="/themes/jack-astors/assets/images/game/axeMaster.jpg" alt="Axe Master">
    <div class="description">
      <h3>Axe Master</h3>
      <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</span>
    </div>
  </div>

  <div class="game__list__card" (click)="showQRCode('Battleship', '/themes/jack-astors/assets/images/qr/battleship.svg')">
    <img src="/themes/jack-astors/assets/images/game/battleship.jpg" alt="Battleship">
    <div class="description">
      <h3>Battleship</h3>
      <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</span>
    </div>
  </div>

  <div class="game__list__card" (click)="showQRCode('Brick Breaker', '/themes/jack-astors/assets/images/qr/brick.svg')">
    <img src="/themes/jack-astors/assets/images/game/brick.jpg" alt="Brick Breaker">
    <div class="description">
      <h3>Brick Breaker</h3>
      <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</span>
    </div>
  </div>

  <div class="game__list__card" (click)="showQRCode('Knock Down Cans', '/themes/jack-astors/assets/images/qr/knock.svg')">
    <img src="/themes/jack-astors/assets/images/game/knock.jpg" alt="Knock Down Cans">
    <div class="description">
      <h3>Knock Down Cans</h3>
      <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</span>
    </div>
  </div>

  <app-qr-view
    *ngIf="isQRVisible"
    (click)="isQRVisible = !isQRVisible"
    [title]="gameName" [imgSrc]="imgSrc"
  ></app-qr-view>
</div>
