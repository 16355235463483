<div class="title">
  <img src="/themes/jack-astors/assets/images/rewarded/inspired.png" alt="">
</div>

<div class="container">
  <div class="container__links">
    <div class="container__links__demo">
      <img src="/themes/jack-astors/assets/images/rewarded/mock.png" alt="">
    </div>
    <h3><span>Waiting Sucks.</span> So members don’t wait.</h3>
    <span class="download">Tap here to download:</span>
    <div class="container__links__elements">
      <img (click)="showQRCode('Scan & download', '/themes/jack-astors/assets/images/qr/appStore.svg', 'Waiting Sucks. So members don’t wait')"
           src="/themes/jack-astors/assets/images/rewarded/app.svg" alt="appStore">
      <img (click)="showQRCode('Scan & download', '/themes/jack-astors/assets/images/qr/google.svg', 'Waiting Sucks. So members don’t wait')"
        src="/themes/jack-astors/assets/images/rewarded/google.svg" alt="googlePlay">
    </div>
  </div>

  <div class="container__brands">
    <img src="/themes/jack-astors/assets/images/rewarded/jacks.png" alt="">
    <img src="/themes/jack-astors/assets/images/logo.svg" alt="">
    <img src="/themes/jack-astors/assets/images/rewarded/scaddabush.png" alt="">
    <img src="/themes/jack-astors/assets/images/rewarded/reds.png" alt="">
    <img src="/themes/jack-astors/assets/images/rewarded/dukes.png" alt="">
    <img src="/themes/jack-astors/assets/images/rewarded/lose.png" alt="">
  </div>
</div>

<app-qr-view
  *ngIf="isQRVisible"
  (click)="isQRVisible = !isQRVisible"
  [title]="title"
  [imgSrc]="imgSrc"
  [description]="description"
></app-qr-view>
