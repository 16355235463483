export const environment = {
  name: 'prod',
  production: true,
  serviceWorker: true,
  apiUrl: 'https://api.excuseme.oongalee.com',
  socketApi: 'wss://api.excuseme.oongalee.com',
  sentry: {
    dsn: 'https://87220bbce1e59ee046cf5e8a909061e8@o4505788477276160.ingest.sentry.io/4505788803842048',
  },
  backgroundPlayer: null,
  backgroundAdPlayer: 'https://dwall-app-ad-player.netlify.app',
  backgroundAdPlayerColor: null,
  featureToggle: {
    theme: 'default',
    showAfterClick: ['/main/home'],
    withTabBar: true,
    withCallWaiter: true,
    withCallWaiterToPay: true,
    withCallWaiterToRepeat: true,
    withCallWaiterOnOffline: true,
    withInvoice: true,
    withServiceCentre: true,
    tabBarAutoHide: undefined,
    idleDelay: undefined,
    ambientLight: {
      callWaiter: null,
      callWaiterToPay: null,
      callWaiterToRepeat: null,
    },
  }
};
