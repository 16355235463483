<div class="box">
  <div class="top" *ngIf="withCallWaiterToPay && withCallWaiterToPayFeatures">
    <div class="header callWaiter" [class.hidden]="callWaiterToPay && withCallWaiterToPayFeatures">
      <img class="header__icon" src="/themes/boston-pizza/assets/images/pay_bill.svg" alt="pay bill">
      <span class="header__text">{{ 'invoice.header' | translate }}</span>
    </div>
    <div class="container" [class.hidden]="callWaiterToPay && withCallWaiterToPayFeatures">
      <div class="group">
        <img class="group__icon" src="/themes/boston-pizza/assets/images/ready_to_pay.svg" alt="">
        <div class="group__title">{{ 'invoice.callWaiterToPay.title' | translate }}</div>
        <div class="group__description">{{ 'invoice.callWaiterToPay.description' | translate }}</div>
        <button
          class="call-waiter-btn"
          [disabled]="callWaiter || callWaiterToRepeat"
          (click)="handlerCallWaiterToPay($event)"
        >
          <img src="/themes/boston-pizza/assets/images/request_bill.svg" alt="">
          <span>{{ 'invoice.callWaiterToPay.action' | translate }}</span>
        </button>
      </div>

    </div>

    <div class="group active" [class.hidden]="!callWaiterToPay || !withCallWaiterToPayFeatures">
      <div class="group__title">{{ 'invoice.callWaiterToPayCancel' | translate }}</div>
      <img class="logo" src="/themes/boston-pizza/assets/images/logo.svg" alt=""/>

      <button class="call-waiter-cancel-btn" (click)="handlerCallWaiterToPay($event)">
        {{ 'invoice.callWaiterToPay.actionCancel' | translate }}
      </button>
    </div>
  </div>

  <div class="top withQR" *ngIf="!withCallWaiterToPay || !withCallWaiterToPayFeatures">
    <div class="header">
      <img class="header__icon" src="/themes/boston-pizza/assets/images/pay_bill.svg" alt="pay bill">
      <span class="header__text">{{ 'invoice.header' | translate }}</span>
    </div>
    <div class="group">
      <img class="group__icon" src="/themes/boston-pizza/assets/images/ready_to_pay.svg" alt="">
      <div class="group__title">{{ 'invoice.title' | translate }}</div>
      <div class="group__description">{{ 'invoice.description' | translate }}</div>
    </div>
    <img class="qr-code" [src]="qrUrl ? qrUrl : '/themes/boston-pizza/assets/images/icon-planet.svg'" alt="">
    <div class="table-name">{{ 'invoice.table' | translate }} {{ tableName }}</div>
  </div>

  <!--  <div class="ads_box">-->
  <!--    <div class="ads_box__title">-->
  <!--      <span class="red">CIBC Aeroplan® </span>-->
  <!--      <span>Visa Infinite* Card</span>-->
  <!--      <div class="description">-->
  <!--        Get closer to your dream vacation with accelerated rewards and enhanced travel benefits.-->
  <!--      </div>-->
  <!--    </div>-->
  <!--    <div class="ads_box__images">-->
  <!--      <img class="ads_box__images__img" src="/themes/boston-pizza/assets/images/cards.png" alt="">-->
  <!--      <div class="qr_box">-->
  <!--        <img class="ads_box__images__qr" src="/themes/boston-pizza/assets/images/qr-ads.svg" alt="">-->
  <!--        <span class="scan">Scan for details</span>-->
  <!--      </div>-->
  <!--    </div>-->
  <!--  </div>-->

</div>
