<div class="back-navigation" (click)="back()">
  <img src="themes/kelseys/assets/images/back.svg" alt="">
</div>

<div class="header">
  <div class="section-name">{{ data.sectionName }}</div>
</div>

<div class="content" *ngIf="data.bottomImgSrc else social">
  <div class="middle">
    <div class="middle__text" *ngIf="data.title || data.topImgSrc">
      <img class="middle__text__img" *ngIf="data.topImgSrc" [src]="data.topImgSrc">
      <div class="middle__text__title" [ngStyle]="{'padding-bottom.px': data.description ? 24 : 0}">{{ data.title }}</div>
      <div class="middle__text__description" *ngIf="data.description" [innerHtml]="data.description"></div>
    </div>
    <div class="middle__qrBox" [class.shadow]="data.topImgSrc">
      <div class="middle__qrBox__qr">
        <img *ngFor="let qrSrc of data.qrImgSrc" [src]="qrSrc" alt="">
      </div>
      <div class="middle__qrBox__qrInfo" *ngIf="data.qrInfo">
        {{ data.qrInfo }}
      </div>
    </div>
  </div>
  <img class="bottom" [src]="data.bottomImgSrc" alt="">
</div>

<ng-template #social>
  <div class="social">
    <img class="social__img" *ngIf="data.topImgSrc" [src]="data.topImgSrc">
    <div class="social__description" *ngIf="data.description" [innerHtml]="data.description"></div>
    <div class="social__qrBox">
      <div class="item">
        <img class="qr" [src]="data.qrImgSrc[0]" alt="">
        <div class="info">FOLLOW ON FB</div>
      </div>
      <div class="item">
        <img class="qr" [src]="data.qrImgSrc[1]" alt="">
        <div class="info">FOLLOW ON TW</div>
      </div>
    </div>
  </div>
</ng-template>
