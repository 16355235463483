import { environment } from '../../environments/environment';
import { Injectable } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, EMPTY, merge, of, Subscription, timer } from 'rxjs';
import { catchError, delay, distinctUntilChanged, filter, pluck, share, switchMap, take, takeUntil } from 'rxjs/operators';
import { webSocket } from 'rxjs/webSocket';
import { AuthService } from './auth.service';
import { RestaurantTableService } from './restaurant-table.service';
import { ConnectionStatusService } from './connection-status.service';
import { DWallIntercom } from './dwall-intercom';
import { HappyHoursService } from './happy-hours.service';
import { MenusService } from './menus.service';
import { InvoiceQrcodeImageService } from './invoice-qrcode-image.service';
import { IdleStatusService } from './idle-status.service';
import { IRestaurantTable } from '../models';
import { SpecialsService } from './specials.service';

@UntilDestroy({ checkProperties: true })
@Injectable({
  providedIn: 'root'
})
export class WebsocketService {

  public readonly connected$ = new BehaviorSubject<boolean>(false);

  private readonly ws$ = webSocket({
    url: `${environment.socketApi}/ws/main`,
    openObserver: {
      next: () => {
        WebsocketService.debug('Open');
        this.sendAuthToken();
      }
    },
    closingObserver: {
      next: () => WebsocketService.debug('Closing')
    },
    closeObserver: {
      next: () => {
        WebsocketService.debug('Close');
        this.connected$.next(false);
      }
    }
  });

  private wsSub: Subscription | null = null;
  private timeoutSub: Subscription | null = null;
  private heartbeatSub: Subscription | null = null;

  constructor(
    private readonly translate: TranslateService,
    private readonly authService: AuthService,
    private readonly idleStatusService: IdleStatusService,
    private readonly restaurantTableService: RestaurantTableService,
    private readonly networkConnection: ConnectionStatusService,
    private readonly dwallIntercom: DWallIntercom,
    private readonly happyHoursService: HappyHoursService,
    private readonly menusService: MenusService,
    private readonly invoiceQrcodeImageService: InvoiceQrcodeImageService,
    private readonly specialsService: SpecialsService
  ) {}

  private static debug(...params: any): void {
    if (!environment.production) {
      console.log('WebSocket - ', ...params);
    }
  }

  public initDemo(): void {
    this.dwallIntercom.messages$.pipe(
      filter(message => message.event.startsWith('demo.')),
      untilDestroyed(this),
    ).subscribe((message) => {
      switch (message.event) {
        case 'demo.mode':
          this.connected$.next(true);
          this.authService.demoMode$.next(true);
          break;
        case 'demo.events':
          this.onMessage(message.data);
          break;
      }
    });
  }

  public init(): void {
    this.authService.logined$.pipe(
      filter(() => !this.authService.isDemoMode),
      switchMap(() => this.networkConnection.status$.pipe(
        distinctUntilChanged(),
        filter((status) => status),
      )),
      untilDestroyed(this)
    ).subscribe(() => {
      this.initWebSocket();
    });

    merge(
      this.authService.logouted$,
      this.authService.demoMode$.pipe(
        distinctUntilChanged(),
        filter((demo) => demo)
      ),
      this.networkConnection.status$.pipe(
        distinctUntilChanged(),
        filter((status) => !status)
      ),
    ).pipe(
      untilDestroyed(this)
    ).subscribe(() => {
      this.unsubscribeAll();
    });
  }

  get isConnected(): boolean {
    return this.connected$.getValue();
  }

  private get table(): IRestaurantTable | null {
    return this.restaurantTableService.table$.getValue();
  }

  private get tableId(): number | null {
    return this.table?.tableId ?? null;
  }

  private get callWaiter(): boolean {
    return this.restaurantTableService.callWaiter$.getValue();
  }

  private get callWaiterToPay(): boolean {
    return this.restaurantTableService.callWaiterToPay$.getValue();
  }

  private get callWaiterToRepeat(): boolean {
    return this.restaurantTableService.callWaiterToRepeat$.getValue();
  }

  private get userInteracts(): boolean {
    return !this.idleStatusService.status$.getValue();
  }

  private unsubscribeAll(): void {
    this.wsSub?.unsubscribe();
    this.wsSub = null;
    this.timeoutSub?.unsubscribe();
    this.timeoutSub = null;
    this.heartbeatSub?.unsubscribe();
    this.heartbeatSub = null;
  }

  private initWebSocket(): void {
    this.unsubscribeAll();

    const share$ = this.ws$.pipe(
      filter((response: any) => !!(response?.type)),
      catchError((error) => {
        console.error('WebSocket:', error);
        return EMPTY;
      }),
      share(),
    );

    this.timeoutSub = merge(of('init'), share$).pipe(
      switchMap(() => of('timeout').pipe(delay(10000))),
      untilDestroyed(this),
    ).subscribe((msg) => {
      console.log(`WebSocket: ${ msg }`);
      this.initWebSocket();
    });

    this.heartbeatSub = timer(1000, 5000).pipe(
      untilDestroyed(this),
    ).subscribe(() => {
      WebsocketService.debug('Heartbeat send');
      this.send('heartbeat', { time: Date.now().toString() });
    });

    this.wsSub = share$.pipe(
      untilDestroyed(this),
    ).subscribe(
      (response: any) => this.onMessage(response),
      (error) => {
        console.error('WebSocket:', error);

        this.initWebSocket();
      },
      () => console.log('WebSocket: Complete'),
    );
  }

  private onMessage(response: { type: string, data: any }): void {
    switch (response.type) {
      case 'loginSuccessful':
        WebsocketService.debug('Received: Login successful.');

        this.connected$.next(true);

        this.restaurantTableService.table$.pipe(
          filter((table) => !!table),
          take(1),
          takeUntil(this.connected$.pipe(
            filter((connected) => !connected),
          )),
          untilDestroyed(this),
        ).subscribe(() => {
          if (this.callWaiter || this.callWaiterToPay || this.callWaiterToRepeat) {
            this.sendCallWaiter();
            this.sendCallWaiterPushNotification();
          }

          this.dwallIntercom.call('battery.level.get');
        });
        break;

      case 'loginError':
      case 'deleteTable':
        WebsocketService.debug('Received: ' +  response.type === 'loginError' ? 'Login error.' : 'Delete table.');
        this.authService.logout();
        this.ws$.complete();
        break;

      case 'tableInfo':
        WebsocketService.debug('Received: Table info.', response.data);
        this.updateTableInfo(response.data);
        break;

      case 'echo':
        if (response.data?.status === false) {
          WebsocketService.debug('Received echo: Cancel Call Waiter.', response.data);
          this.restaurantTableService.callWaiter$.next(false);
        }

        if (response.data?.pay === false) {
          WebsocketService.debug('Received echo: Cancel Call Waiter To Pay.', response.data);
          this.restaurantTableService.callWaiterToPay$.next(false);
        }

        if (response.data?.repeat === false) {
          WebsocketService.debug('Received echo: Cancel Call Waiter To Repeat.', response.data);
          this.restaurantTableService.callWaiterToRepeat$.next(false);
        }

        if (response.data?.getPlaylistItems === true) {
          WebsocketService.debug('Received echo: Get playlist items.', response.data);
          this.dwallIntercom.call('playlist.get_items');
        }

        if (response.data?.deviceTurnOff === true) {
          WebsocketService.debug('Received echo: Turn off device.', response.data);
          this.dwallIntercom.call('device.turnOff');
        }
        break;

      case 'heartbeat':
        WebsocketService.debug('Received: Heartbeat', response.data?.time);
        break;

      default:
        WebsocketService.debug('Received: Unknown message', response);
        break;
    }
  }

  public updateTableInfo(data: any): void {
    if (data.clickableMediaConfig) {
      data.clickableMediaConfig = JSON.parse(data.clickableMediaConfig);
    }

    this.restaurantTableService.updateData(
      data
    ).pipe(
      untilDestroyed(this),
    ).subscribe();

    if (data.language) {
      if (!this.userInteracts) {
        this.translate.use(data.language);
      }

      this.translate.setDefaultLang(data.language);
    }

    if (data.happyHours) {
      this.happyHoursService.updateByCollection(
        data.happyHours
      ).pipe(
        untilDestroyed(this),
      ).subscribe();
    } else {
      this.happyHoursService.clear().pipe(
        untilDestroyed(this),
      ).subscribe();
    }

    if (data.menus) {
      this.menusService.update(
        data.menus
      ).pipe(
        untilDestroyed(this),
      ).subscribe();
    } else {
      this.menusService.clear().pipe(
        untilDestroyed(this),
      ).subscribe();
    }

    if (data.specials) {
      this.specialsService.syncSpecials(
        data.specials
      ).pipe(
        untilDestroyed(this),
      ).subscribe();
    }

    if (data.invoiceQRCodeUrl) {
      this.invoiceQrcodeImageService.update(
        data.invoiceQRCodeUrl
      ).pipe(
        untilDestroyed(this),
      ).subscribe();
    } else {
      this.invoiceQrcodeImageService.clear().pipe(
        untilDestroyed(this),
      ).subscribe();
    }
  }

  public send(type: string, data: any): void {
    WebsocketService.debug('Send:', type, data);
    this.ws$.next({ type, data });
  }

  public sendAuthToken(): void {
    this.send('loginWidget', {
      JWT: this.authService.getToken()
    });
  }

  public sendCallWaiter(): void {
    if (!this.tableId) {
      return;
    }

    if (!this.isConnected) {
      return;
    }

    this.send('echo', {
      tableId: this.tableId,
      status: this.callWaiter,
      pay: this.callWaiterToPay,
      repeat: this.callWaiterToRepeat,
    });
  }

  public sendPlaylistItems(items: []): void {
    if (!this.tableId) {
      return;
    }

    if (!this.isConnected) {
      return;
    }

    this.send('echo', {
      tableId: this.tableId,
      playlistItems: items,
    });
  }

  public sendBatteryLevel(level: number, charging = false): void {
    if (!this.tableId) {
      return;
    }

    if (!this.isConnected) {
      return;
    }

    const prevValue = this.restaurantTableService.batteryLevel$.getValue();
    const prevLevel = prevValue?.level ?? 0;

    this.send('batteryLevel', {
      level, charging
    });

    this.restaurantTableService.batteryLevel$.next({
      level, charging
    });

    this.translate.getTranslation(this.translate.defaultLang).pipe(
      pluck('pushNotification'),
      untilDestroyed(this),
    ).subscribe((text) => {
      let notificationMessage = null;

      if (prevLevel > 0.02 && level <= 0.02) {
        notificationMessage = text.batteryLess2;
      }
      else if (prevLevel > 0.1 && level <= 0.1) {
        notificationMessage = text.batteryLess10;
      }
      else if (prevLevel > 0.3 && level <= 0.3) {
        notificationMessage = text.batteryLess30;
      }

      if (notificationMessage) {
        this.send('pushNotification', {
          title: `Table ${this.table?.tableName}`,
          body: notificationMessage,
          recipientsCategory: 'all',
        });
      }
    });
  }

  public sendCallWaiterPushNotification(): void {
    const table = this.table;

    if (!table) {
      return;
    }

    if (!this.isConnected) {
      return;
    }

    this.translate.getTranslation(this.translate.defaultLang).pipe(
      pluck('pushNotification'),
      untilDestroyed(this),
    ).subscribe((text) => {
      const messages = [];

      if (this.callWaiter) {
        messages.push(text.callWaiter);
      }

      if (this.callWaiterToPay) {
        messages.push(text.billPlease);
      }

      if (this.callWaiterToRepeat) {
        messages.push(text.oneMoreRound);
      }

      if (messages.length > 0) {
        this.send('pushNotification', {
          title: `Table ${this.table?.tableName}`,
          body: messages.join(', '),
          recipientsCategory: 'all',
        });
      }
    });
  }

}
