import { environment as original } from './environment.prod';

export const environment = {
  ...original,
  name: 'prod-boston-pizza-style',
  backgroundAdPlayerColor: '#a10f25',
  featureToggle: {
    ...original.featureToggle,
    theme: 'boston-pizza',
    withServiceCentre: true,
    withCallWaiterToPay: false,
    tabBarAutoHide: false,
    idleDelay: 30,
    ambientLight: {
      callWaiter: { r: 255, g: 0, b: 0, a: 1 },
      callWaiterToPay: { r: 0, g: 255, b: 0, a: 1 },
      callWaiterToRepeat: { r: 255, g: 255, b: 0, a: 1 }
    },
  }
};
