import { Component } from '@angular/core';
import {animate, style, transition, trigger} from '@angular/animations';

type State = 'sections' | 'spinach' | 'cards' | 'events' | 'kids' | 'app' |'social';
export interface ISectionData {
  topImgSrc?: string;
  sectionName: string;
  title?: string;
  description?: string;
  qrImgSrc: string[];
  qrInfo?: string;
  bottomImgSrc?: string;
}

@Component({
  selector: 'app-kelseys-sections-grid',
  templateUrl: './kelseys-sections-grid.component.html',
  styleUrls: ['./kelseys-sections-grid.component.scss'],
  animations: [
    trigger('InOutAnimation', [
      transition(':enter', [
        style({ transform: 'translateX(100%)' }),
        animate('400ms ease-in', style({ transform: 'translateX(0%)' }))
      ]),
      transition(':leave', [
        animate('400ms ease-in', style({ transform: 'translateX(100%)' }))
      ])
    ])
  ]
})
export class KelseysSectionsGridComponent {

  public state: State = 'sections';
  public sectionData!: ISectionData;
  public sectionsData = {
    spinach: {
      sectionName: 'Free Spinach Dip',
      title: 'Get a FREE Spinach Dip when you sign up!',
      description: 'Give the gift of Kelseys Ultimate Dining Card\nto the Road Trippers in your life craving a little\ndventure!',
      qrImgSrc: ['/themes/kelseys/assets/images/spinachQr.png'],
      qrInfo: 'Scan to sign up',
      bottomImgSrc: '/themes/kelseys/assets/images/freeSpinachBg.jpg'
    },
    cards: {
      sectionName: 'Gift Cards',
      title: 'Kelseys Ultimate\nDining Card',
      description: 'Give the gift of Kelseys Ultimate Dining Card\nto the Road Trippers in your life craving a little\nadventure!',
      qrImgSrc: ['/themes/kelseys/assets/images/cardsQr.png'],
      qrInfo: 'Scan to LEARN',
      bottomImgSrc: '/themes/kelseys/assets/images/giftBg.jpg'
    },
    events: {
      sectionName: 'Group Events & Parties',
      title: 'BRING YOUR PARTY TO THE\nROADHOUSE',
      description: 'The roadhouse restaurant is the perfect place for\nparties, celebrations, sports team & corporate\nevents.',
      qrImgSrc: ['/themes/kelseys/assets/images/eventsQr.png'],
      qrInfo: 'Scan to LEARN',
      bottomImgSrc: '/themes/kelseys/assets/images/eventsBg.jpg'
    },
    kids: {
      sectionName: 'Kids Zone',
      topImgSrc: '/themes/kelseys/assets/images/kids_hello.png',
      title: 'welcome to the kelseys\nkids zone!',
      qrImgSrc: ['/themes/kelseys/assets/images/kidsQr.png'],
      qrInfo: 'kids zone',
      bottomImgSrc: '/themes/kelseys/assets/images/kidsBottom.jpg'
    },
    app: {
      sectionName: 'Kelseys APP',
      title: 'DOWNLOAD THE KELSEYS\nMOBILE APP',
      description: 'Download our mobile app and get $5 off your\nfirst order. Use code: <b>kelseys5off</b>',
      qrImgSrc: [
        '/themes/kelseys/assets/images/qr-aple.svg',
        '/themes/kelseys/assets/images/qr-google.svg'
      ],
      bottomImgSrc: '/themes/kelseys/assets/images/appBg.jpg'
    },
    social: {
      sectionName: 'Social',
      topImgSrc: '/themes/kelseys/assets/images/connect.png',
      description: 'Kelseys Original Roadhouse.\nAwesome since 1978! 🍔',
      qrImgSrc: [
        '/themes/kelseys/assets/images/socialQrFb.png',
        '/themes/kelseys/assets/images/socialQrInst.png'
      ],
    },
  };

  public get isSections(): boolean {
    return this.state === 'sections';
  }

  public get isSpinach(): boolean {
    return this.state === 'spinach';
  }

  public get isCards(): boolean {
    return this.state === 'cards';
  }

  public get isEvents(): boolean {
    return this.state === 'events';
  }

  public get isKids(): boolean {
    return this.state === 'kids';
  }

  public get isApp(): boolean {
    return this.state === 'app';
  }

  public get isSocial(): boolean {
    return this.state === 'social';
  }

  public setState(state: State): void {
    if (state !== 'sections'){
      this.sectionData = this.sectionsData[state];
    }
    this.state = state;
  }

  public closeSection($event: boolean): void {
    if ($event) {
      this.setState('sections');
    }
  }
}
