<div class="title">
  <h1>{{ 'watch.title' | translate }}</h1>
</div>

<div class="container">
  <div class="item__card" (click)="setState('tv')">
    <div class="item__card__image">
      <img src="/themes/jack-astors/assets/images/watch/tv.svg" alt="">
    </div>
    <h3>Jack’s TV</h3>
  </div>

  <div class="item__card" (click)="setState('chalkboards')">
    <div class="item__card__image">
      <img src="/themes/jack-astors/assets/images/watch/chalkboards.svg" alt="">
    </div>
    <h3>chalkboards</h3>
  </div>

  <div class="item__card" (click)="setState('sport')">
    <div class="item__card__image">
      <img src="/themes/jack-astors/assets/images/watch/sports.svg" alt="">
    </div>
    <h3>sports</h3>
  </div>
</div>

<app-chalkboards
  *ngIf="isChalkboards"
  [slides]="slides"
></app-chalkboards>

<app-jaks-video
  *ngIf="isTv"
  [videos]="videos"
></app-jaks-video>

<app-jaks-sports
  *ngIf="isSport"
></app-jaks-sports>
