
  <div class="g_categories" *ngIf="isOnline && gCategories.length > 0">
    <button class="g_category"
            *ngFor="let gCategory of gCategories"
            [class.active]=isActiveSection(gCategory.hierarchyId)
            (click)="setActive(gCategory.hierarchyId)"
    >
      <img *ngIf="!gCategory.icon" class="icon" src="/themes/kelseys/assets/images/tabbar_menu.svg" alt="">
      <img *ngIf="gCategory.icon" class="icon" [src]="gCategory.icon" alt="">
      <span class="name" [class.active]=isActiveSection(gCategory.hierarchyId)>
          {{ gCategory.name }}
        </span>
    </button>
  </div>

  <app-loading *ngIf="isLoading && isOnline && gCategories.length > 0 else menu"></app-loading>

  <ng-template #menu>

    <div class="noConnect" *ngIf="!isOnline else category">
      <img class="noConnect__icon" src="themes/kelseys/assets/images/wifi.svg" alt="No Connect">
      <div class="noConnect__text">
        No internet connection
      </div>
    </div>

    <ng-template #category>
      <div class="noConnect" *ngIf="gCategories.length === 0">
        <img class="noConnect__icon" src="themes/kelseys/assets/images/tabbar_menu.svg" alt="No menu">
        <div class="noConnect__text">
          The menu has not been added yet
        </div>
      </div>

      <div class="category"
           *ngFor=" let category of categories"
           (click)="showCategory(category)"
      >
        <img class="category__bg" [src]="category.sanitizedUrl ? category.sanitizedUrl : getDefaultBg(category)" alt="" />
        <div class="category__name">
          <span>{{ category.categoryName }}</span>
        </div>
      </div>
    </ng-template>

    <app-menu-category
      [ngStyle]="{'margin-top.px': this.categories.length > 1 ? 0 : 118}"
      [isSolo]="this.categories.length <= 1"
      [category]="this.currentCategory"
      (closeCategory) = "this.isCategoryOpen = false"
      (openProduct)="this.showProduct($event)"
      *ngIf="isCategoryOpen">
    </app-menu-category>

  </ng-template>
