import { environment } from '../../environments/environment';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject, fromEvent, Observable } from 'rxjs';
import { filter, map, pluck, switchMap, tap } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { IDWallMedia } from '../models';

type IntercomMethod = 'init'
  | 'device.turnOff'
  | 'playlist.get_items'
  | 'playlist.get_playing_now'
  | 'playlist.stop'
  | 'playlist.resume'
  | 'ambient_light.on'
  | 'ambient_light.off'
  | 'battery.level.get'
  | 'brightness.get'
  | 'brightness.set'
  | 'demo.method';
type IntercomMethodData = any;

type IntercomEvent = 'playlist.items'
  | 'playlist.playing_now'
  | 'playlist.playing_finished'
  | 'battery.level'
  | 'brightness.level'
  | 'demo.mode'
  | 'demo.events';
type IntercomEventData = any;

export interface IIntercomEventMessage {
  type: 'dwall-events';
  version: string;
  event: IntercomEvent;
  data: IntercomEventData;
}

export interface IIntercomMethodMessage {
  type: 'dwall-events';
  version: string;
  method: IntercomMethod;
  data: IntercomMethodData;
}

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class DWallIntercom {

  private readonly port$ = new BehaviorSubject<MessagePort | null>(null);

  public readonly messages$ = new Subject<IIntercomEventMessage>();
  public readonly currentMedia$ = new BehaviorSubject<IDWallMedia | null>(null);

  get connected(): boolean {
    return !!this.port$.getValue();
  }

  get connected$(): Observable<boolean> {
    return this.port$.pipe(map((port) => !!port));
  }

  private static debug(...params: any): void {
    if (!environment.production) {
      console.log('DWAll Intercom - ', ...params);
    }
  }

  private static methodMessage(method: IntercomMethod, data: IntercomMethodData = null): IIntercomMethodMessage {
    return { type: 'dwall-events', version: '0.0.1', method, data};
  }

  init(): void {
    fromEvent<MessageEvent>(window, 'message').pipe(
      filter((event) => {
        return event.data === 'dwall-handshake' && typeof event.ports !== undefined && typeof event.ports[0] !== undefined;
      }),
      tap((event) => DWallIntercom.debug('Handshake', JSON.stringify(event))),
      map((event) => event.ports[0]),
      tap((port) => this.port$.next(port)),
      switchMap((port) => {
        port.start();
        this.call('init');

        return fromEvent<MessageEvent>(port, 'message');
      }),
      tap((event) => DWallIntercom.debug('Received raw:', event)),
      map((event) => {
        DWallIntercom.debug('Message type:', typeof event.data);

        // TODO: Remove this hack when Android will be fixed
        if (typeof event.data === 'string') {
          const data = JSON.parse(event.data.replace('\/', ''));

          DWallIntercom.debug('Message parse:', data);

          return data;
        }

        return event.data;
      }),
      filter((message) => {
        return message.type && message.version && message.event;
      }),
    ).pipe(
      untilDestroyed(this),
    ).subscribe((message) => {
      DWallIntercom.debug('Received:', message);

      this.messages$.next(message);
    });

    this.messages$.pipe(
      filter((message) => message.event === 'playlist.playing_now'),
      pluck('data')
    ).pipe(
      untilDestroyed(this),
    ).subscribe((data) => {
      this.currentMedia$.next(data);
    });
  }

  call(method: IntercomMethod, data: IntercomMethodData = null): boolean {
    const port = this.port$.getValue();
    if (!port) {
      return false;
    }

    const message = DWallIntercom.methodMessage(method, data);
    // TODO: Remove this hack when Android will be fixed
    port.postMessage(JSON.stringify(message));
    DWallIntercom.debug('Sent:', message);

    return true;
  }

}
